import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/QNAP/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software for your INSTAR IP Camera",
  "path": "/Software/Other_Platforms/QNAP/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "All INSTAR IP cameras are supported by the QNAP Surveillance Station. Manage all your cameras and video recordings.",
  "image": "./P_SearchThumb_QNAP.png",
  "social": "/images/Search/P_SearchThumb_QNAP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_QNAP_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Camera Surveillance Software' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='All INSTAR IP cameras are supported by the QNAP Surveillance Station. Manage all your cameras and video recordings.' image='/images/Search/P_SearchThumb_QNAP.png' twitter='/images/Search/P_SearchThumb_QNAP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/QNAP/' locationFR='/fr/Software/Other_Platforms/QNAP/' crumbLabel="QNAP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "qnap-surveillance-station",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#qnap-surveillance-station",
        "aria-label": "qnap surveillance station permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP Surveillance Station`}</h3>
    <p>{`The Surveillance Station is professional network surveillance Video Management System (VMS). Being one of the Turbo NAS standard applications, the Surveillance Station can be activated on the QTS App Center to turn the Turbo NAS into a professional Network Video Recorder (NVR) system for real-time monitoring, recording, playback, alarm notifications, Intelligent Video Analytics's (IVA), and management to safeguard your assets and property when used with supported IP cameras.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in the `}<a href="http://www.qnap.com/i/station/surveillance-overview-s.php" target="_blank" rel="noopener noreferrer">{`QNAP Surveillance Station`}</a>{` we recommend you to download the latest version from QNAP app store.`}</p>
    <h3 {...{
      "id": "add-a-hd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-hd-camera",
        "aria-label": "add a hd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a HD Camera`}</h3>
    <p>{`Start the Surveillance App from the QNAP web interface and click on the `}<strong parentName="p">{`Add`}</strong>{` button. Now choose type in the address for your local HD camera, the HTTP & RTSP port and your login credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArElEQVQoz8WLwQrCMBBE84eSVC+2pm1SsNnZ3SiIhyoevPnXkoIg6KkefMxh5y1jbO1t3W36/Wrb2V2wPjgfXTs4H9dtqNroSgbrwyvR+Vj5aJvexOsD0/12mbIKKCkjCyOVg4mKmWsWZtAsR0HxB1XT8Gk8nomSiADMLCJCAJcKAkolEhGenyBiZgCiamIIypRz1m98+ndjwLoYk1Vl8Vh/4I/j/AMmpTQu5QlBhhVh0Zrp3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/e4706/QNAP_Surveillance_Station_02.avif 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d1af7/QNAP_Surveillance_Station_02.avif 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7f308/QNAP_Surveillance_Station_02.avif 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d31ba/QNAP_Surveillance_Station_02.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/a0b58/QNAP_Surveillance_Station_02.webp 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/bc10c/QNAP_Surveillance_Station_02.webp 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/966d8/QNAP_Surveillance_Station_02.webp 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7ed5b/QNAP_Surveillance_Station_02.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/81c8e/QNAP_Surveillance_Station_02.png 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/08a84/QNAP_Surveillance_Station_02.png 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig1.`}</strong>{` Start the Surveillance App from the QNAP web interface and click on the Add button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/33d1d/QNAP_Surveillance_Station_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdElEQVQoz32QTU/CQBCG+6vRq0E+DLDtbverB7YgR4NAa/XMT9CEgzcih65GoJQWhW2pASIqik/mMJPMk3kzWrEGz6v62UU1XwEFAEsAlnRU0lF5U7Csm9vRLAJYqBn7KgGjUNW1fBUZmFJsckYJNjFCGEGoA4wgMgxo6BhBHdSIiRgljG42GSUEY4szrQIMTNkOznm703W8u07PcVyv0+11Hbfn3Lje7VX7mhDC2EakWzjnmgER32qU0kbDfnzyH/3pOAjl88vrePK+UkuVrFRyf/9ACLYsi39Do5TtOkqpbdu+789ms3WaSikXi0UcxwCAXC53cnIqhNil+5I55+zzshBCSvn29r5er6MoXi6XSZL0+33P81zX5b/4Idu2LaWMokgpFYahUirLsjRNsywbDoemaR7G3suMsXq9PhqNgiCYTCZBEIRhOJ/Pp9NpHMeDwQBjfFTe0Ww2W63W5ZbmN4QQ/8Xev+1PDl71t2wd57f8AS1zr/zjpDsMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/e4706/QNAP_Surveillance_Station_03.avif 230w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/d1af7/QNAP_Surveillance_Station_03.avif 460w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/7f308/QNAP_Surveillance_Station_03.avif 920w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/d31ba/QNAP_Surveillance_Station_03.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/a0b58/QNAP_Surveillance_Station_03.webp 230w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/bc10c/QNAP_Surveillance_Station_03.webp 460w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/966d8/QNAP_Surveillance_Station_03.webp 920w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/7ed5b/QNAP_Surveillance_Station_03.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/81c8e/QNAP_Surveillance_Station_03.png 230w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/08a84/QNAP_Surveillance_Station_03.png 460w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/c0255/QNAP_Surveillance_Station_03.png 920w", "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/33d1d/QNAP_Surveillance_Station_03.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7a757d5bd67b3c5f50c4ce0e88be886e/c0255/QNAP_Surveillance_Station_03.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig2.`}</strong>{` Type in the camera´s local IP address and your login credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/277065573c63bcb229d1eeb903b5d651/33d1d/QNAP_Surveillance_Station_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVQoz43NXU/CMBSA4f3/O2NgaHAfXduzdrLVGxXYmD+ExGAQxyYsgFC2ADNzxKBI5M1J04vztIqqo9qNeXGtXTb1um6pRjkNAzcM62p/waqJVd2qa6gcvTwbOqpppnKpYYPYNiWc2UAJEAKUYGQCwQRbGCGgxEImA8ps4Ha5yQCAkFvOlaZuUpt91+74QfjU8QO/F7a7fjfo+b2wG4T3D48A5R4A2F8xzhVkYc4Y55wx5gnxOholk+lzMh+8vU+mqczyLN8URTF4GRJCHMfhBylQPsErLISIomg2m+V5lqZplmWr1WqzKfFw+Beu2P5nzxuP4ziOkyRZLpdyvc7zfLvdnoVd153P50VRVKBqt9udhVutVhRFUsqPgxaLhZSy3+9TSk/iKs/zhBB3PxNCuK7Lj/qN2en+x87pjvEnWS3a2YTs2L4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/277065573c63bcb229d1eeb903b5d651/e4706/QNAP_Surveillance_Station_04.avif 230w", "/en/static/277065573c63bcb229d1eeb903b5d651/d1af7/QNAP_Surveillance_Station_04.avif 460w", "/en/static/277065573c63bcb229d1eeb903b5d651/7f308/QNAP_Surveillance_Station_04.avif 920w", "/en/static/277065573c63bcb229d1eeb903b5d651/d31ba/QNAP_Surveillance_Station_04.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/277065573c63bcb229d1eeb903b5d651/a0b58/QNAP_Surveillance_Station_04.webp 230w", "/en/static/277065573c63bcb229d1eeb903b5d651/bc10c/QNAP_Surveillance_Station_04.webp 460w", "/en/static/277065573c63bcb229d1eeb903b5d651/966d8/QNAP_Surveillance_Station_04.webp 920w", "/en/static/277065573c63bcb229d1eeb903b5d651/7ed5b/QNAP_Surveillance_Station_04.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/277065573c63bcb229d1eeb903b5d651/81c8e/QNAP_Surveillance_Station_04.png 230w", "/en/static/277065573c63bcb229d1eeb903b5d651/08a84/QNAP_Surveillance_Station_04.png 460w", "/en/static/277065573c63bcb229d1eeb903b5d651/c0255/QNAP_Surveillance_Station_04.png 920w", "/en/static/277065573c63bcb229d1eeb903b5d651/33d1d/QNAP_Surveillance_Station_04.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/277065573c63bcb229d1eeb903b5d651/c0255/QNAP_Surveillance_Station_04.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig3.`}</strong>{` Adjust the video quality of the camera´s live stream to fit your needs.`}</p>
    <p><strong parentName="p">{`What to do in case of a model mismatch?`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "770px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/f4b77/QNAP_Surveillance_Station_X1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/ElEQVQoz4WRQW/aQBBG/f+vOSRqpKZS0yigJK0oLuBd2CWtgATsXTs2GIzteGa9GEdOI9RWag5RBVTNsU8z0jcjvcuMYdGuRSnrc8Z6vF7vn34gjUa70yGEdP+HUTt6d/bmtHb0/tP5x9seC/hXMbZt9064d3JXjnAd4UrXk64npCek68jtOBiMDPOs/uX8slW7aDWafhiqdZ5AFGfzFJZKKa1Urrad71gVRVk+lOVDVVVhGBotSluEdPucdruB7yNkcbwAzJQCQIiyDFYalUKANE2lFJbVaTabpmkOBkPDbJpm0yQWIYQEQaCUihZLzECvS7jzo9pF2iaZ798DxHHMOT88PDo+Pj44OOh0OkarbRFKGWd7GRGjRaQQdfmAfhCf1VLex/t7rTUCjse3V1eX9Xr95ORtr9czPrestkUZ+ytDBkmSAGIyD5fzqTsLg/l8Gs5m4SwIAiGcyWTsea7neVJKo21R2u29ygBRtNQIwzD7tsirYqWVLnJdaL1aFVVVrddrRCzLcnswzhlnjHP+T14solLjEJ+u1Y+f3x8fH59+rYrf6/L5+fnl5WWz2QCA1no6nRpsx172fR8RkzjJAAqFVaFzvf1UkqYq3walVJqmAJDn+avMGKOUjkYjKaUQwnVdWwjbEXuklI5tTyYTx3Ec294vb25u/gBJ9hbvw6CdlwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/e4706/QNAP_Surveillance_Station_X1.avif 230w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/d1af7/QNAP_Surveillance_Station_X1.avif 460w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/5fbe9/QNAP_Surveillance_Station_X1.avif 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/a0b58/QNAP_Surveillance_Station_X1.webp 230w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/bc10c/QNAP_Surveillance_Station_X1.webp 460w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/cf403/QNAP_Surveillance_Station_X1.webp 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/81c8e/QNAP_Surveillance_Station_X1.png 230w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/08a84/QNAP_Surveillance_Station_X1.png 460w", "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/f4b77/QNAP_Surveillance_Station_X1.png 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2d08a8e44ca4fecc8ab9024f044b9f1b/f4b77/QNAP_Surveillance_Station_X1.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig4.`}</strong>{` If you want to integrate a model that is not supported by the Surveillance Station yet, you will be faced with a Model Mismatch error.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "770px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2e67d7dde66fdca71926656cc20114ef/f4b77/QNAP_Surveillance_Station_X2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACmUlEQVQoz3XSbU/aUBgG4P6wZfsXc9u/WLbMOQgiY4liiGHQoaKoZW7YJeB46QulUhOdUC3CJqxSRemrLT0tTEz0kwuQbUbd9f0+9znPeaAnT589ePjo8cjI6soyEl2KIcjneDyX+kqkUhiGkSSZzWYJgkin05lMFscJgiAoiorH4w6HA/oAw+8mJ5ffBz8+f7H68pV/whOemzsWhHq9Vq/VWy3x4uKid35umqZhGKZptk0TAKCqqtPphGKxWDKZzBGkY3TU8fr1m7Exn88nCA2cwHEMS6dSNE3n8/lmswkAMAY0Tbu6ugqFQtDCwiKKfsFw3DU+7p6Y8Hg8Pp/v5OQEw7BCocAwzNbW1vb2tiiKtm2DAcMwLi8vZ2ZmoOjSUiaTIUnS6/VOTU29HVBkRZYky7Z7vd6vAQBA+w9VVQEAgUAACoVCCIKsr69PT08Hg0GXy+X1ehVFaRw2FEUBAJgD4AZd17vdbj/MMMxOcadUKoXD4dnZWafT6ff7ZVk+Eo407cy4j6qqlmXBMAydafr19bVlWQiCBINBt9sNw7Asy83jZrvdtm3busMwDNu2o9EoxLK7p6entVotmUggyEogEIhEIrIsNYSGrhuWZYE7dF23LCsWi0HzkXkURQcLkCkwZJ7G6A1SFMVDnpdluf+xd2iaBgBAEARKJBMoitL0Ri6HM5u5neJGpfJNFEVBEBRF+d+bdV1fW1uDaJpmGGZvb2+3j2VZlitzYqslS3K327332sP+/f19iKIokiSLxSLHccMjyuVyq9XieV6UJFlR7E7n1sCGq1qtVvvNm5v95qFhWBIlgef5gzr/k9d1/dbM/4WpHJXP51m29DfNfa+qPw6ai8vc/KezXc7sdCwAboaHm1OpVH4Da/TMxAMYXiMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e67d7dde66fdca71926656cc20114ef/e4706/QNAP_Surveillance_Station_X2.avif 230w", "/en/static/2e67d7dde66fdca71926656cc20114ef/d1af7/QNAP_Surveillance_Station_X2.avif 460w", "/en/static/2e67d7dde66fdca71926656cc20114ef/5fbe9/QNAP_Surveillance_Station_X2.avif 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2e67d7dde66fdca71926656cc20114ef/a0b58/QNAP_Surveillance_Station_X2.webp 230w", "/en/static/2e67d7dde66fdca71926656cc20114ef/bc10c/QNAP_Surveillance_Station_X2.webp 460w", "/en/static/2e67d7dde66fdca71926656cc20114ef/cf403/QNAP_Surveillance_Station_X2.webp 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e67d7dde66fdca71926656cc20114ef/81c8e/QNAP_Surveillance_Station_X2.png 230w", "/en/static/2e67d7dde66fdca71926656cc20114ef/08a84/QNAP_Surveillance_Station_X2.png 460w", "/en/static/2e67d7dde66fdca71926656cc20114ef/f4b77/QNAP_Surveillance_Station_X2.png 770w"],
              "sizes": "(max-width: 770px) 100vw, 770px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2e67d7dde66fdca71926656cc20114ef/f4b77/QNAP_Surveillance_Station_X2.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig5.`}</strong>{` Simply change the camera name under Multimedia/Image to a supported camera model and retry.`}</p>
    <h3 {...{
      "id": "add-a-vga-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-vga-camera",
        "aria-label": "add a vga camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a VGA Camera`}</h3>
    <p>{`Start the Surveillance App from the QNAP web interface and click on the `}<strong parentName="p">{`Add`}</strong>{` button. Now choose type in the address for your local VGA camera, the HTTP port and your login credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArElEQVQoz8WLwQrCMBBE84eSVC+2pm1SsNnZ3SiIhyoevPnXkoIg6KkefMxh5y1jbO1t3W36/Wrb2V2wPjgfXTs4H9dtqNroSgbrwyvR+Vj5aJvexOsD0/12mbIKKCkjCyOVg4mKmWsWZtAsR0HxB1XT8Gk8nomSiADMLCJCAJcKAkolEhGenyBiZgCiamIIypRz1m98+ndjwLoYk1Vl8Vh/4I/j/AMmpTQu5QlBhhVh0Zrp3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/e4706/QNAP_Surveillance_Station_02.avif 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d1af7/QNAP_Surveillance_Station_02.avif 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7f308/QNAP_Surveillance_Station_02.avif 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d31ba/QNAP_Surveillance_Station_02.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/a0b58/QNAP_Surveillance_Station_02.webp 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/bc10c/QNAP_Surveillance_Station_02.webp 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/966d8/QNAP_Surveillance_Station_02.webp 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7ed5b/QNAP_Surveillance_Station_02.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/81c8e/QNAP_Surveillance_Station_02.png 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/08a84/QNAP_Surveillance_Station_02.png 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig6.`}</strong>{` Start the Surveillance App from the QNAP web interface and click on the Add button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/984a7d5a6ece7f93e11251a73b883391/33d1d/QNAP_Surveillance_Station_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVQoz3XS207CMBjA8b2qPoU8BZowUINs0NP3daSb8cDR+BaGGw8hmqGCAYcOCFBDYYio//SiF/2lbVor65DMSWn/2DnIu7ZDbIfaDs25LOfSQ4fkXJo1wy6QTDLsQsmoopUpEJcBcCoBgDPBqGCUloqcUkaJmRBSdIFzFAJBAGcohODMk2gdFYpUIEpERAC4rFQbzetKrV5vXl1Wa9Vao95o1uqNs/MLIQTiAgKAAJBSWiVCJaKUC+37qvXwdPPYfXkbhGHn5bX7GY/eo4+51rd394wxz/PkRpYAQw1WSnWen9+jSGvd7b7GcTybTVOpvd3dnXQ6HQQBrtYmeMm+cadjzGwQRfFopLUOgiCfzyultrb9A/d6vfF4PJlMptOpNs3nc611u93+49ibuFwuh2EYx3EURcOkfr8/HA5brRbn/F+8zPf9U1NgWs+VUlsXXmEppZeESQCAG22uWWd5nrf4HZwvXs+84ZrBz8SvvgDwStKiV8Fj1gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/984a7d5a6ece7f93e11251a73b883391/e4706/QNAP_Surveillance_Station_06.avif 230w", "/en/static/984a7d5a6ece7f93e11251a73b883391/d1af7/QNAP_Surveillance_Station_06.avif 460w", "/en/static/984a7d5a6ece7f93e11251a73b883391/7f308/QNAP_Surveillance_Station_06.avif 920w", "/en/static/984a7d5a6ece7f93e11251a73b883391/d31ba/QNAP_Surveillance_Station_06.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/984a7d5a6ece7f93e11251a73b883391/a0b58/QNAP_Surveillance_Station_06.webp 230w", "/en/static/984a7d5a6ece7f93e11251a73b883391/bc10c/QNAP_Surveillance_Station_06.webp 460w", "/en/static/984a7d5a6ece7f93e11251a73b883391/966d8/QNAP_Surveillance_Station_06.webp 920w", "/en/static/984a7d5a6ece7f93e11251a73b883391/7ed5b/QNAP_Surveillance_Station_06.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/984a7d5a6ece7f93e11251a73b883391/81c8e/QNAP_Surveillance_Station_06.png 230w", "/en/static/984a7d5a6ece7f93e11251a73b883391/08a84/QNAP_Surveillance_Station_06.png 460w", "/en/static/984a7d5a6ece7f93e11251a73b883391/c0255/QNAP_Surveillance_Station_06.png 920w", "/en/static/984a7d5a6ece7f93e11251a73b883391/33d1d/QNAP_Surveillance_Station_06.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/984a7d5a6ece7f93e11251a73b883391/c0255/QNAP_Surveillance_Station_06.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig7.`}</strong>{` Type in the camera´s local IP address and your login credentials.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/692332d5a8e9d3276cb27be8dc25415f/33d1d/QNAP_Surveillance_Station_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVUlEQVQoz43M/0vCQBjH8f3/Pya1ItLN3fPc89x0LCq12V8iRDFhgl+SW+A2nV7olCyLejGOD+zeZ9mOOK83z66d2o174Xi2I2xXXLpw6Yqr/QDbBdsRFw1v++2G7Xjn9aZVuxGOIEJQRBJAgpAAoulKECC83QDPdQiREJmkREESJYKv2LpuuCCZFTMzMXe6vaj/1H2MHqN+p9vrRdvRi/p39w8kJTNLKYlIEimlrKYnFLNS2zoIgng4nExnz2P9mkyms7dFXuTF0hjz8vIKAL7vqyMWEattu49Ho5HWuizLLMuWy2WeZ2VZGmPiOP4hrrLqbAfBeDyez+da6zzPi6JYrVbr9fp/cbuttTbGbDYbc1Dtv+NWq5UkyWKxSNP0/SBN0yzLBoMBIv4aV8IwvD0RhmEQBMfXPmOlVPWk7/v8i+rvNxYzI6IQAhEBAHfkDn4FJz4AnrHZgnQemkkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/692332d5a8e9d3276cb27be8dc25415f/e4706/QNAP_Surveillance_Station_07.avif 230w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/d1af7/QNAP_Surveillance_Station_07.avif 460w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/7f308/QNAP_Surveillance_Station_07.avif 920w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/d31ba/QNAP_Surveillance_Station_07.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/692332d5a8e9d3276cb27be8dc25415f/a0b58/QNAP_Surveillance_Station_07.webp 230w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/bc10c/QNAP_Surveillance_Station_07.webp 460w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/966d8/QNAP_Surveillance_Station_07.webp 920w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/7ed5b/QNAP_Surveillance_Station_07.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/692332d5a8e9d3276cb27be8dc25415f/81c8e/QNAP_Surveillance_Station_07.png 230w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/08a84/QNAP_Surveillance_Station_07.png 460w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/c0255/QNAP_Surveillance_Station_07.png 920w", "/en/static/692332d5a8e9d3276cb27be8dc25415f/33d1d/QNAP_Surveillance_Station_07.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/692332d5a8e9d3276cb27be8dc25415f/c0255/QNAP_Surveillance_Station_07.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig8.`}</strong>{` Adjust the video quality of the camera´s live stream to fit your needs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArElEQVQoz8WLwQrCMBBE84eSVC+2pm1SsNnZ3SiIhyoevPnXkoIg6KkefMxh5y1jbO1t3W36/Wrb2V2wPjgfXTs4H9dtqNroSgbrwyvR+Vj5aJvexOsD0/12mbIKKCkjCyOVg4mKmWsWZtAsR0HxB1XT8Gk8nomSiADMLCJCAJcKAkolEhGenyBiZgCiamIIypRz1m98+ndjwLoYk1Vl8Vh/4I/j/AMmpTQu5QlBhhVh0Zrp3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/e4706/QNAP_Surveillance_Station_02.avif 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d1af7/QNAP_Surveillance_Station_02.avif 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7f308/QNAP_Surveillance_Station_02.avif 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/d31ba/QNAP_Surveillance_Station_02.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/a0b58/QNAP_Surveillance_Station_02.webp 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/bc10c/QNAP_Surveillance_Station_02.webp 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/966d8/QNAP_Surveillance_Station_02.webp 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/7ed5b/QNAP_Surveillance_Station_02.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/81c8e/QNAP_Surveillance_Station_02.png 230w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/08a84/QNAP_Surveillance_Station_02.png 460w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png 920w", "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/33d1d/QNAP_Surveillance_Station_02.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8fc13f154ce6737fe9ecce6fbd07c6c/c0255/QNAP_Surveillance_Station_02.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig9.`}</strong>{` Both cameras are now available after confirming your settings.`}</p>
    <h3 {...{
      "id": "event-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#event-management",
        "aria-label": "event management permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Event Management`}</h3>
    <p>{`Open the event manager to activate the alarm recording in case of a motion detection event or bind the recording function to the external alarm input - so that an external sensor (e.g. a PIR motion detector) can trigger an alarm recording. Go to Advanced settings to adjust the length of the alarm recording and for a simple storage management.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/696bccbc631f21fab038abe829553cde/33d1d/QNAP_Surveillance_Station_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsklEQVQoz32Qy27TQBRA/bts6H/Apk2aQtWKFpo4jkjiGY/t+DEvW1HMS4ptlQoJ0U26KCUOJFGal+1BjYsoLXB0dDWbc3U1UrnW3D55/eRIeXrc2K61SnWwq8BdBVYU8ExRKw1YacCyAsp1UJLVHVktyaBcV/fq6s5JU3oFHd3lutpGQNUh0DVgaMAykKWjjqGbGtShaunIMpDTMZ2OaSJom4ZtGpxgqdTCDbfrYuxiSrmPKXMwIZRhwghjtost2yWUY8p03dAQAhBqCGkaclxXarea73rdYfL98ioZjn4ko/FynaWZyITIfymEWK3XQRAwxnzf9zZwzqWOS3pBcHn1bZR8nY6T+WycrRd5uszTpciLcBOvVt1ul1LK7iBRSoOer3qDfXP6Ek+PnMkLe1w4nKRC3G74e0woe9vje+Di0X66dTB/fDDfOlwUczDMhBDZ/2JC3gS9z4Px+0+T+MssPr+Oinl+PVvmeX4T50LMF/842/O8KOyH/Q9np/HZx99GcRyGcRTd2O+HnHP6J1Lxb4wxyjih7K738B8gmabZarefH1erNbm2QZZvH/eoPuAnX1n7ok1IyjQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696bccbc631f21fab038abe829553cde/e4706/QNAP_Surveillance_Station_08.avif 230w", "/en/static/696bccbc631f21fab038abe829553cde/d1af7/QNAP_Surveillance_Station_08.avif 460w", "/en/static/696bccbc631f21fab038abe829553cde/7f308/QNAP_Surveillance_Station_08.avif 920w", "/en/static/696bccbc631f21fab038abe829553cde/d31ba/QNAP_Surveillance_Station_08.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/696bccbc631f21fab038abe829553cde/a0b58/QNAP_Surveillance_Station_08.webp 230w", "/en/static/696bccbc631f21fab038abe829553cde/bc10c/QNAP_Surveillance_Station_08.webp 460w", "/en/static/696bccbc631f21fab038abe829553cde/966d8/QNAP_Surveillance_Station_08.webp 920w", "/en/static/696bccbc631f21fab038abe829553cde/7ed5b/QNAP_Surveillance_Station_08.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696bccbc631f21fab038abe829553cde/81c8e/QNAP_Surveillance_Station_08.png 230w", "/en/static/696bccbc631f21fab038abe829553cde/08a84/QNAP_Surveillance_Station_08.png 460w", "/en/static/696bccbc631f21fab038abe829553cde/c0255/QNAP_Surveillance_Station_08.png 920w", "/en/static/696bccbc631f21fab038abe829553cde/33d1d/QNAP_Surveillance_Station_08.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/696bccbc631f21fab038abe829553cde/c0255/QNAP_Surveillance_Station_08.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig10.`}</strong>{` Set your camera to record a video in case of an alarm trigger event.`}</p>
    <h3 {...{
      "id": "monitor-and-playback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#monitor-and-playback",
        "aria-label": "monitor and playback permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Monitor and Playback`}</h3>
    <p>{`Use the Monitor window to keep an eye on your cameras real-time feeds from your INSTAR cameras. Whenever a suspicious event is found, take a snapshot to keep a record and take immediate actions accordingly. The Playback window allows you to quickly find videos of suspicious incidents on the Surveillance Station with the support of an intuitive timeline, image preview, Intelligent Video Analytics's (IVA), and more.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/261136f8a989ceae9332283f1fc471b5/33d1d/QNAP_Surveillance_Station_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACn0lEQVQozy3O7U8SAQDHcf4FV8vNF9SmjKWb8xVtEQ/anXhndhygR84jju42UAOsQ/CpJBZu4G2ob2VYWHPm3Bo9gMVZgDd8mIBiW72qubZepM1yS0Pvms7f6+9n+4k+FrfTn7bT2Ww2vbCylMxl2S+5zHr2Q34ts5HjtgrLGzlus7DCctzU/OvnLxOzsXezr96/eJOce5sU7e7++lc63t3Z4Ut/+aNDni8JxyX+6PDnj++ftza41GJ+bTm7lJqfm302Pb2QSLAsG4/HF1k2nUqJDg4OBEFYWVvP5fObm8VUOpNk2dXV1Xhi4cnT6WHvo7t2x+OREVdvr06vI0my09ZJkqTdbnc4HCJBEHhBCEem+vo8brfb5/MFg0Gfzzc6OhoOh6PRqMvlwvEOHMfLL5SXlZWdO3f+ovhS9eVqqVR6hgeHHmq1WpvNxjCM3+9nGCYSiUxMTHi9XrPZrNForFYb3oGPj40X8gXvsBdBtBbLnRMsCILz3v2mpiaapiEIwjDM7XY7nU6r1arX64HTURRFEITD4QiHw4FAgKZprA07w6jOIJVKEQQBQRCCYJVKVV9fD8NwQ0NDbW2tWCwmCKKiokIikZwGEIIgAACe3uaFQJAxYkaT6bbB0IphRovFolarNY0a+VW5QqmSya5oUbS5+QZBWIYGH9isncB1wGi8dYJLpaOv37bT6UwsFpucnJyZmYlGozKZrBFsRBDE7/f3OHsAANCiqN1uLxaLHMcNDPSPhcZO8DHPe/oHMAyjKEqn03V3d9M0rVQqYRimKKqrqwvHcRiGZTIZgtxkGCYUCoEg2NraJtrf/7O391unN1RWVioUCrVajaJoe3t7S0sLBEEmk8lsNiMIUldXV1VVVVNTQ5Kkx+ORSCRy+bX/DchXSwGwdKkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/261136f8a989ceae9332283f1fc471b5/e4706/QNAP_Surveillance_Station_10.avif 230w", "/en/static/261136f8a989ceae9332283f1fc471b5/d1af7/QNAP_Surveillance_Station_10.avif 460w", "/en/static/261136f8a989ceae9332283f1fc471b5/7f308/QNAP_Surveillance_Station_10.avif 920w", "/en/static/261136f8a989ceae9332283f1fc471b5/d31ba/QNAP_Surveillance_Station_10.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/261136f8a989ceae9332283f1fc471b5/a0b58/QNAP_Surveillance_Station_10.webp 230w", "/en/static/261136f8a989ceae9332283f1fc471b5/bc10c/QNAP_Surveillance_Station_10.webp 460w", "/en/static/261136f8a989ceae9332283f1fc471b5/966d8/QNAP_Surveillance_Station_10.webp 920w", "/en/static/261136f8a989ceae9332283f1fc471b5/7ed5b/QNAP_Surveillance_Station_10.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/261136f8a989ceae9332283f1fc471b5/81c8e/QNAP_Surveillance_Station_10.png 230w", "/en/static/261136f8a989ceae9332283f1fc471b5/08a84/QNAP_Surveillance_Station_10.png 460w", "/en/static/261136f8a989ceae9332283f1fc471b5/c0255/QNAP_Surveillance_Station_10.png 920w", "/en/static/261136f8a989ceae9332283f1fc471b5/33d1d/QNAP_Surveillance_Station_10.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/261136f8a989ceae9332283f1fc471b5/c0255/QNAP_Surveillance_Station_10.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig11.`}</strong>{` Use live-view to watch real-time feeds from the IP cameras.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63a6a5b9df6123157be645bd5624347e/33d1d/QNAP_Surveillance_Station_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnElEQVQozzXPX0wSAQDHcd56bz1Vc3Ot5lqZyp/ZUiHgOO7gPP7qoeKDbSE1J1nNE3FT/ptzB9MOYzhjuvIpHW/aS/8kIJzCBs1R/ktnmiGK/8ib11L77vf6efgxgrHUh+RqJPplNvxuJvw+Mf0xFQvGpqfi/5eYCSVmQ8lYOBkPf41HkrFIYjY0Fw9/jkYZO9ldmqaz25nc3vbRwe7h3g6V26ePDmkqR1O5Yyq3m/md2VxfW174sZBa+ja3svh9eT4Vn4lGQkHGn1yOpun5hcVfm5v7B4eZ7Z1sdi+9lfm5vpFOb62srE4Fg2/Gxl76/Xhbm6mjw9ndbbM7Hj95arXaGDRNH9P0xOSk1+sNBAKvTiIIwuVy+f3+8ZMCgcDw8LDP52ttbW1oaOjt7bXZbH19ff8wRVGkZ8BkMuE4bjAYOjs7XS6X2+02m804jlssFrfb7fP5RkdHCYJoamqSyWQQBFmt1jPc3m7CMEypVCoUChzH+/v7SZJ0Op09PT0ej2dwcJAgCLvd3tXVVVdXhyCIRCJxOrvPcGOjXgLDer3e4XAYjUatVqtWq41G48jIyNDQEEEQFoulublZfBKCICqViiTJ08/H93WNNwsLpVJEp9OhqKy0tLQSRQ2GRwKBEKmshCFYoVAgCMIXCHg8HovF4nJ5z0/xEUWpVaqrV/KlEkitlNdgVRAIKOVoDVYlEvKlsFiOIhAIgIBAyOexmcV5ly/e5Za/GCAZG+mldHZNAMvzC4ruCGBWhai4DGCWi9hccdFtPrsCLCkDhEh1hVh+nVl2+dqtC3kF585fusHhmp+5GeMT3refXivvGYr4SjZYzRZrOHAtS6xhgxgH1LCgWjZUywQ1JQBWDFSVwPUc9AH6sEOkbalvsf4F/AdtKz+yer0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63a6a5b9df6123157be645bd5624347e/e4706/QNAP_Surveillance_Station_11.avif 230w", "/en/static/63a6a5b9df6123157be645bd5624347e/d1af7/QNAP_Surveillance_Station_11.avif 460w", "/en/static/63a6a5b9df6123157be645bd5624347e/7f308/QNAP_Surveillance_Station_11.avif 920w", "/en/static/63a6a5b9df6123157be645bd5624347e/d31ba/QNAP_Surveillance_Station_11.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63a6a5b9df6123157be645bd5624347e/a0b58/QNAP_Surveillance_Station_11.webp 230w", "/en/static/63a6a5b9df6123157be645bd5624347e/bc10c/QNAP_Surveillance_Station_11.webp 460w", "/en/static/63a6a5b9df6123157be645bd5624347e/966d8/QNAP_Surveillance_Station_11.webp 920w", "/en/static/63a6a5b9df6123157be645bd5624347e/7ed5b/QNAP_Surveillance_Station_11.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63a6a5b9df6123157be645bd5624347e/81c8e/QNAP_Surveillance_Station_11.png 230w", "/en/static/63a6a5b9df6123157be645bd5624347e/08a84/QNAP_Surveillance_Station_11.png 460w", "/en/static/63a6a5b9df6123157be645bd5624347e/c0255/QNAP_Surveillance_Station_11.png 920w", "/en/static/63a6a5b9df6123157be645bd5624347e/33d1d/QNAP_Surveillance_Station_11.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63a6a5b9df6123157be645bd5624347e/c0255/QNAP_Surveillance_Station_11.png",
              "alt": "QNAP Surveillance Station for your INSTAR IP Camera",
              "title": "QNAP Surveillance Station for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig12.`}</strong>{` Quickly find videos of suspicious incidents on the Surveillance Station with the support of an intuitive timeline.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      